<template>
    <div id="app" class="wrapper">
        <div class="login">
            <ValidationObserver ref="observer" v-slot="{ invalid }" class="login__inner" tag="div">
                <div>
                    <img src="@/assets/images/logo.png" v-bind="logoAttrs">
                </div>
                <ValidationProvider v-slot="{ errors }" name="id" rules="alpha_dash|required" :custom-messages="customMessages" tag="div">
                    <input v-model="input.login_id" type="text" placeholder="アカウントID" @keyup.enter="keyupEnterLogIn()">
                    <p v-show="errors[0] && errors[0] !== 'idは必須項目です'" class="errorMessage">{{ errors[0] }}</p>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" name="password" rules="alpha_dash|required" :custom-messages="customMessages" tag="div">
                    <input v-model="input.password" type="password" placeholder="パスワード" @keyup.enter="keyupEnterLogIn()">
                    <p v-show="errors[0] && errors[0] !== 'passwordは必須項目です'" class="errorMessage">{{ errors[0] }}</p>
                </ValidationProvider>
                <Loader :loader="loader" />
                <div v-if="!loader">
                    <button :class="X_validatedButtonStyle" type="button" :disabled="$_setValidatedDisabled(invalid)" @click="handleLogIn()">ログイン</button>
                </div>
            </ValidationObserver>
        </div>
        <Modal :modal="modal" @action="modalAction($event)" @close="$_modalClose()" />
    </div>
</template>

<script>
    "use strict";

    import Loader from "@/components/Loader.vue";

    import common from "@/mixins/common";

    export default {
        "components": {
            Loader,
        },
        "mixins": [
            common,
        ],
        data() {
            return {
                "customMessages": {
                    "alpha_dash": "半角英数字で入力してください",
                },
                "input": {
                    "login_id": "",
                    "password": "",
                },
                "loader": false,
                "logoAttrs": {
                    "alt": "旭産業",
                    "title": "旭産業",
                },
            };
        },
        "methods": {
            keyupEnterLogIn() {
                // enterキーでログイン
                let flag = false;
                for (let key in this.input) {
                    if (this.input[key]) {
                        flag = true;
                    } else {
                        flag = false;
                        break;
                    }
                }
                if (flag) {
                    this.handleLogIn();
                }
            },
            handleLogIn() {
                this.loader = true;
                // ログイン
                common.$_axios.post(
                    `${common.$_configs.apiUrl}/login`,
                    this.input
                ).then(response => {
                    localStorage.setItem("token", response.data.access_token);
                    localStorage.setItem("expired", response.data.expired_at);
                    this.$router.push({ "name": "search" });
                }).catch(() => {
                    this.$_modalError("loginError");
                    this.loader = false;
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .button--oval--cancel {
        font-weight: bold;
        &:hover {
            background: #898989;
            border-color: #898989;
        }
    }

    .button--oval--primary {
        background-color: #00bfff;
        border-color: #00bfff;
        &:hover {
            background-color: #3dceff;
            border-color: #3dceff;
        }
    }

    .errorMessage {
        margin-top: 8px;
        color: #fff;
        font-size: 14px;
    }
</style>
